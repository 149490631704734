import { __awaiter } from "tslib";
import React, { useEffect, useState } from 'react';
import { styled } from '@glitz/react';
import Quantity from 'Shared/Quantity';
import Image, { Preset, Ratio } from 'Shared/Image/Ratio';
import Price from 'Pricing/Price';
import Link from 'Shared/Link';
import { resetLinkDecorator } from 'Shared/Generic/A';
import { figma, large, mediaMinMedium, mediaMinMicro, mediaMinSmall, mediaMinTiny, medium, small, sourceSansProSemiBold, lightBorderGrey, } from 'Shared/Style';
import { translate, useDynamicData } from '@avensia/scope';
import { Trash } from 'Shared/Icon';
import { testIdProps } from '../../test-automation';
import { Theme } from 'Shared/Button';
import { media } from '@glitz/core';
import currentPageIsCheckout from 'Checkout/current-page-is-checkout';
import { useSelector } from 'Shared/State';
import { TextArea } from 'Shared/Fields/Textarea';
import Additional from 'Checkout/CheckoutPage/FullCart/Additional';
import { getCurrentUser } from 'Shared/dynamic-data';
import { FeedbackSlaveButton, useFeedback } from 'Shared/Button/Feedback';
import MiddlewareStocks from 'Stock/Stocks';
export default styled(function Item(props) {
    var _a, _b, _c;
    const { arePricesHidden = false } = props;
    const isCheckoutPage = useSelector(state => currentPageIsCheckout(state.currentPage));
    const [customerComment, setCustomerComment] = useState(props.additional.customerComment);
    const [isMounted, setMounted] = useState(false);
    const currentUser = useDynamicData(getCurrentUser());
    const isB2B = currentUser === null || currentUser === void 0 ? void 0 : currentUser.isB2B;
    const [addState, pushAdd] = useFeedback();
    const onRemoveClick = () => __awaiter(this, void 0, void 0, function* () {
        const action = () => __awaiter(this, void 0, void 0, function* () {
            setMounted(true);
            if (props.isGift)
                props.setAdditionalData();
            else
                yield props.updateCartItemQuantity(0);
            if (!isMounted)
                return;
            return Promise.resolve();
        });
        yield pushAdd(action());
    });
    useEffect(() => {
        return () => {
            setMounted(false);
        };
    }, []);
    return (React.createElement(styled.Li, Object.assign({}, testIdProps.CartLineItem, { css: { marginTop: isCheckoutPage ? large : 'inherit' } }),
        React.createElement(Media, { to: props.url },
            React.createElement(Image, { src: props.viewData.imageUrl, ratio: Ratio.TwoToThree, preset: Preset.Tiny, alt: props.displayName })),
        React.createElement(Body, null,
            React.createElement(Flex, null,
                React.createElement(Content, null,
                    React.createElement(Title, { to: props.url }, props.displayName),
                    React.createElement(StyledFeedbackButton, { onClick: onRemoveClick, "aria-label": translate('/Shared/Remove'), as: Theme.None, state: addState },
                        React.createElement(Remove, Object.assign({}, testIdProps.RemoveMiniCartItemButton, { color: lightBorderGrey })))),
                React.createElement(AdditionalContentBox, null,
                    React.createElement(Additional, { itemProps: props.viewData, isB2B: isB2B })),
                isCheckoutPage && isB2B && (React.createElement(CustomerComment, { value: customerComment, onChange: (e) => setCustomerComment(e.currentTarget.value), onBlur: (e) => props.updateCustomerComment(e.currentTarget.value) })),
                isCheckoutPage && isB2B && props.isMiddlewareStocksEnabled && !props.viewData.ismtm && (React.createElement(MiddlewareStocks, { hasMiddlewareStocks: props.viewData.middlewareStock ? true : false, quantity: (_a = props.viewData.middlewareStock) === null || _a === void 0 ? void 0 : _a.quantity, isValid: (_b = props.viewData.middlewareStock) === null || _b === void 0 ? void 0 : _b.isValid, leadtime: (_c = props.viewData.middlewareStock) === null || _c === void 0 ? void 0 : _c.availableOn, hasResolved: true })),
                props.isGift ? (React.createElement("div", null, translate('/Checkout/Free'))) : (React.createElement(QuantityRow, null,
                    React.createElement(Quantity, { value: props.quantity, update: props.updateCartItemQuantity }),
                    !arePricesHidden && React.createElement(StyledPrice, { current: props.totalPrice, original: props.viewData.originalPrice })))))));
}, {
    display: 'flex',
});
export function itemKey(props, index) {
    return `${props.code}_${index}`;
}
const Flex = styled.div({
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    margin: { y: 'auto' },
});
export const Body = styled.div({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    flexGrow: 1,
    width: '100%',
});
export const Content = styled.div({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
});
const Remove = styled(Trash, {
    cursor: 'pointer',
});
const QuantityRow = styled.div({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
});
export const Media = styled(Link, {
    flex: {
        grow: 0,
        shrink: 1,
        basis: '35%',
    },
    ['@media ' + mediaMinTiny]: {
        flexBasis: '30%',
    },
    marginRight: medium,
});
export const Title = styled(Link, resetLinkDecorator, sourceSansProSemiBold(medium), Object.assign(Object.assign(Object.assign({ maxWidth: '80%', lineHeight: '15px', paddingBottom: small }, media(mediaMinMedium, { fontSize: medium })), media(mediaMinSmall, { fontSize: figma })), media(mediaMinMicro, { fontSize: figma })));
export const AdditionalContentBox = styled.div({
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: medium,
});
const StyledPrice = styled(Price, {
    whiteSpace: 'nowrap',
    paddingLeft: small,
    textAlign: 'right',
});
const CustomerComment = styled(TextArea, { marginLeft: 0, marginBottom: medium });
export const StyledFeedbackButton = styled(FeedbackSlaveButton, {
    padding: { y: 0 },
    height: 'auto',
    alignItems: 'flex-end',
});
