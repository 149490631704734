import { useSelector } from 'Shared/State';
import { availableStocks, epsilon, limitedStocks, small, unavailableStocks } from 'Shared/Style';
import { styled } from '@glitz/react';
import React from 'react';
import { translate } from '@avensia/scope';
export default function MiddlewareStocks(props) {
    const lowStocksLimitSettings = useSelector(state => state.appShellData.siteSettings.lowStocksLimit);
    return (React.createElement(StocksContainer, null,
        !props.hasMiddlewareStocks && props.hasResolved && (React.createElement(StocksUnavailableText, null, translate('/MiddlewareStocks/BackInStockNotAvailable'))),
        props.hasMiddlewareStocks && props.hasResolved && !props.isValid && (React.createElement(StocksUnavailableText, null, translate('/MiddlewareStocks/InventoryNotAvailable'))),
        props.hasMiddlewareStocks && props.isValid && (React.createElement(React.Fragment, null,
            props.quantity > 0 && props.quantity > lowStocksLimitSettings && (React.createElement(React.Fragment, null,
                React.createElement(StocksAvailableText, null, `${props.quantity} ${translate('/MiddlewareStocks/AvailableStocks')}`),
                props.leadtime && (React.createElement(StocksAvailableText, null, `${translate('/MiddlewareStocks/ExpectedDelivery')} ${props.leadtime}`)))),
            props.quantity > 0 && lowStocksLimitSettings >= props.quantity && (React.createElement(React.Fragment, null,
                React.createElement(StocksLimitedText, null, `${props.quantity} ${translate('/MiddlewareStocks/AvailableStocks')}`),
                props.leadtime && (React.createElement(StocksLimitedText, null, `${translate('/MiddlewareStocks/ExpectedDelivery')} ${props.leadtime}`)))),
            props.quantity === 0 && (React.createElement(React.Fragment, null,
                React.createElement(StocksUnavailableText, null, `${props.quantity} ${translate('/MiddlewareStocks/AvailableStocks')}`),
                props.leadtime && (React.createElement(StocksUnavailableText, null, `${translate('/MiddlewareStocks/ExpectedDelivery')} ${props.leadtime}`))))))));
}
const StocksContainer = styled.div({
    marginBottom: small,
    marginRight: 0,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
});
const StocksText = styled.p({
    fontSize: epsilon,
    margin: {
        top: small,
        bottom: 0,
    },
});
const StocksAvailableText = styled(StocksText, {
    color: availableStocks,
});
const StocksLimitedText = styled(StocksText, {
    color: limitedStocks,
});
const StocksUnavailableText = styled(StocksText, {
    color: unavailableStocks,
});
